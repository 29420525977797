
import { defineComponent } from 'vue';
import ProcessSelector from '@/processes/common/components/organisms/ProcessSelector.vue';
import { routes } from '@/router';

export default defineComponent({
  name: 'App',
  components: { ProcessSelector },
  props: {
    routes: { type: Array, default: routes }
  },
});
