import { getItemFromStorage } from '@/processes/common/utils';

const {
  user = {
    api_token: '',
    email: '',
    first_name: '',
    last_name: '',
    user_id: '',
  }
} = getItemFromStorage('PDT-storage');

export default ({
  state: {
    apiToken: user.api_token,
    email: user.email,
    fistName: user.first_name,
    lastName: user.last_name,
    userId: user.user_id,
  },
});
