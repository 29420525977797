
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { routes } from '@/router';

export default defineComponent({
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  name: 'ProcessSelector',
  props: {
    routes: { type: Array, default: routes }
  },
  data() {
    const routesValue = routes.map((el) => {
      el.name = this.t(el.name);
      return el;
    });
    console.log('abra', routesValue);
    return {
      routesValue
    };
  }
});
