import { RouteRecordRaw } from 'vue-router';
import LinkFormatter from '@/processes/common/routes/linkFormatter';
import { DEMONSTRATION_COMPONENTS } from './links';

const links = [
  DEMONSTRATION_COMPONENTS
];

const routes: Array<RouteRecordRaw> = LinkFormatter.createLinks(links);

export default routes;
