import { createApp } from 'vue';
import dayjs from 'dayjs';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './processes/common/store';
import en from './translations/en.json';
import ru from './translations/ru.json';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $dayjs(date?: dayjs.ConfigType, option?: dayjs.OptionType, locale?: string): dayjs.Dayjs;
  }
}

const i18n = createI18n(
  {
    messages: { ru, en },
    fallbackLocale: 'ru',
    locale: 'ru',
  },
);

const app = createApp(App);
app.config.globalProperties.$dayjs = dayjs;
app.use(i18n).use(store).use(router)
  .mount('#app');
