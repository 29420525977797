import { createStore } from 'vuex';
import { KeyValue, State } from '@/processes/common/type';
import userStore from './user';

export default createStore<State>({
  state: {
    user: userStore.state
  },
  mutations: {
    updateUserProperty(state : State, data: KeyValue) {
      state.user[data.key] = data.value;
    },
  },
  actions: {

  },
  modules: {

  },
});
