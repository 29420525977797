import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import routesCommon from '../processes/common/routes';
import routesSorting from '../processes/sorting/routes';

export const routes: Array<RouteRecordRaw> = [
  ...routesCommon,
  ...routesSorting,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
