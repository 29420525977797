import { Link } from '@/processes/common/type';

const prefix = '/common';

export const DEMONSTRATION_COMPONENTS : Link = {
  prefix,
  url: 'components',
  name: 'demo',
  componentPath: () => import(/* webpackChunkName: "demonstration" */ '../components/pages/Demonstration.vue')
};

export default null;
