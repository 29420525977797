import { RouteRecordRaw } from 'vue-router';
import { Link } from '@/processes/common/type';

export default class LinkFormatter {
  static createLinks(links: Array<Link>) : Array<RouteRecordRaw> {
    return links.map(({
      prefix = '',
      url = '',
      name = '',
      componentPath: component
    }) => ({
      path: `${prefix}/${url}`,
      name,
      component,
    }));
  }
}
